export default [
  {
    header: 'Inicio',
    icon: 'HomeIcon',
    action: 'read',
    resource: 'Auth',
    href: 'https://pixinvent.ticksy.com/',
    title: 'INICIO',
  },
]

// action: 'read',
//     subject: 'Auth',
